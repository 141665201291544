<template>
  <div>
    <router-link :to="{ name: 'moderator.promotions.create' }" class="btn btn-primary btn-sm py-2 px-2 float-right">Add New</router-link>
    <h2 class="h3">Signals Promotion Codes</h2>
    <div class="card">
      <div class="card-body">
        <table class="table table-responsive-md table-striped">
          <thead>
            <tr>
              <th width="5%">#</th>
              <th width="50%">Link</th>
              <th width="10%">Months</th>
              <th width="15%">Usage Left</th>
              <th width="15%">Expiry</th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(code, i) in codes" :key="`code-${i}`">
              <td>{{ i+1 }}</td>
              <td>
                <a :href="`/promo/${code.code}`" id="copy-text" target="_blank">https://myforexglobal.com/promo/{{ code.code }}</a>
                <a href="#" class="copy-link float-right" @click.prevent="copyCode(code)">{{ copied ? 'copied!' : 'copy'}}</a>
              </td>
              <td>{{ code.months }}</td>
              <td>{{ code.left }}</td>
              <td>{{ code.expiry }}</td>
              <td>
                <a href="#" @click.prevent="remove(code)"><i class="lni lni-trash-can"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      codes: [],
      copied: false
    }
  },

  methods: {
    copyCode(code) {
      navigator.clipboard.writeText(`https://myforexglobal.com/promo/${code.code}`)
      this.copied = true

      setTimeout(() => {
        this.copied = false
      }, 2000)
    },

    fetch() {
      this.$loader.start()
      this.$axios.get('/api/v1/moderator/promotions').then(response => {
        this.codes = response.data.codes
        this.$loader.stop()
      })
    },

    remove(code) {
      this.$axios.delete(`/api/v1/moderator/promotions/${code.id}`).then(() => {
        this.$toasted.show('Discount code has been removed');
        this.fetch();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
a.copy-link {
  display: none;
}

table tr:hover a.copy-link {
  display: inline-block;
  text-align: right;
}
</style>